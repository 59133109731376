.whatsapp-float {
  padding-left: 12px;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  cursor: pointer;
}

.whatsapp-float img {
  width: 35px;
  height: 35px;
  margin-top: 12px;
}

.whatsapp-float:hover {
  background-color: #22c15f;
}
